<template>
    <div>
        <base-header type="gradient-success" class="pb-2 pt-3 pt-md-6">
            <!-- Card stats -->
            <div class="row">
              
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt-3">
            <div class="row">
              <div>
                <router-link to="/gallery" class="my-2"><img src="/img/home/hero.jpg" class="hero-img"></router-link>
              </div>

            </div>
        </div>

    </div>
</template>
<script>
  export default {
    data() {
      return {

      };
    },
    methods: {

    },
    mounted() {
      // this.initBigChart(0);
    }
  };
</script>
<style></style>

<template>
    <div class="byme row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="row my-3" style="text-align:left">
                <h1>My Account</h1>
            </div>
            <div class="row my-3" style="text-align:left">
                <div class="menu">
                    <base-button @click="selectMenu('account-details')" :type="(menuSelected == 'account-details') ? 'primary' : 'secondary'">Account Details</base-button>
                    <base-button @click="selectMenu('my-orders')" :type="(menuSelected == 'my-orders') ? 'primary' : 'secondary'">My Orders</base-button>
                    <base-button @click="$router.push('byme-upload')" type="warning">Byme Upload</base-button>
                    <router-link v-if="isAdmin"  to="/dashboard" class="btn btn-info">Admin Panel</router-link>
                    <base-button @click="logout()" type="danger">Logout</base-button>
                    <!-- <base-button @click="selectMenu('wishlist')" :type="(menuSelected == 'wishlist') ? 'primary' : 'secondary'">Wishlist</base-button>
                    <base-button @click="selectMenu('rewards')" :type="(menuSelected == 'rewards') ? 'primary' : 'secondary'">Rewards</base-button>
                    <base-button @click="selectMenu('affiliate')" :type="(menuSelected == 'affiliate') ? 'primary' : 'secondary'">Affiliate</base-button>
                    <base-button @click="selectMenu('reseller-status')" :type="(menuSelected == 'reseller-status') ? 'primary' : 'secondary'">Reseller Status</base-button> -->
                </div>
            </div>
            <!-- <content-placeholders v-show="products.length == 0" class="m-5">
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="2" />
            </content-placeholders>
            <div v-show="products.length > 0" > -->
            <div v-show="menuSelected == 'my-orders'">
                <div class="my-3" style="text-align:left">
                    <h3 class="py-3">My Orders</h3>
                    <div v-if="orders.length == 0">
                        <content-placeholders v-show="!orderLoaded" class="m-5">
                            <content-placeholders-heading :img="true" />
                            <content-placeholders-text :lines="2" />
                            <content-placeholders-heading :img="true" />
                            <content-placeholders-text :lines="2" />
                            </content-placeholders>
                        <div class="py-4" v-if="orderLoaded">There is no orders found yet</div>
                        <!-- {{ orders.length }} -->
                    </div>
                    <div v-if="orders.length > 0">
                        <div class="table-responsive">
                            <base-table class="table align-items-center table-flush"
                                        tbody-classes="list"
                                        :data="orders" v-show="orders.length > 0">
                            <template slot="columns">
                                <th>OrderID</th>
                                <th>Status</th>
                                <th>Items</th>
                                <th>Subtotal</th>
                                <th>Discount</th>
                                <th>Coupon</th>
                                <th>Total</th>
                                <th></th>
                            </template>

                            <template slot-scope="{row}">
                                <th scope="row">
                                <div class="media align-items-center clickable" @click="view(row.id)">
                                    <a href="#" class="avatar mr-3" v-show="row.img">
                                    <img alt="Image placeholder" :src="row.img">
                                    </a>
                                    <div class="media-body">
                                    <span class="name mb-0 text-sm">{{row.orderID}}</span>
                                    </div>
                                </div>
                                </th>
                                <td>
                                  <div style="padding: 2px;border-radius: 5px;text-align:center;margin-bottom:1px" :class="{'text-white': true,
                                      'bg-warning': row.orderStatus == 'Unfulfilled' || row.orderStatus == null,
                                      'bg-success': row.orderStatus == 'Fulfilled' || row.orderStatus,}
                                      ">{{(row.orderStatus || "Unfulfilled")}}</div>
                                  <div style="padding: 2px;border-radius: 5px;text-align:center;" :class="{'text-white': true,
                                      'bg-warning': row.paymentStatus == 'Unpaid' || row.paymentStatus == null,
                                      'bg-success': row.paymentStatus == 'Paid' || row.paymentStatus,}
                                      ">{{(row.paymentStatus || "Unpaid")}}</div>
                                </td>
                                <td class="price">
                                {{ row.totalBeforeDiscount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        }) }}
                                </td>
                                <td class="price">
                                {{ row.discount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })  }}
                                </td>
                                <td class="price">
                                {{ row.couponCode }}
                                </td>
                                <td class="price">
                                {{ row.total.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })  }}
                                </td>
                                <td class="price">
                                <div v-for="product, index in row.cart" :key="index">
                                    <b>{{ product.productName }}</b>
                                    <span style="color:gray"> for </span>
                                    <b>{{ product.childname }}</b>
                                </div>
                                
                                </td>

                            </template>

                            </base-table>
                            </div>


                    </div>
                </div>
            </div>
            <div v-show="menuSelected == 'account-details'">
                <div class="col-xl-8 order-xl-1">
                    <card shadow type="secondary">
                        <h6 class="heading-small text-muted mb-4">User information</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                                label="Email address"
                                                placeholder="johndoe@example.com"
                                                input-classes="form-control-alternative"
                                                v-model="model.email"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                                label="First name"
                                                placeholder="First name"
                                                input-classes="form-control-alternative"
                                                v-model="model.firstName"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                                label="Last name"
                                                placeholder="Last name"
                                                input-classes="form-control-alternative"
                                                v-model="model.lastName"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Address -->
                        <h6 class="heading-small text-muted mb-4">Contact information</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <base-input alternative=""
                                                label="Address"
                                                placeholder="Home Address"
                                                input-classes="form-control-alternative"
                                                v-model="model.address"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <base-input alternative=""
                                                label="City"
                                                placeholder="City"
                                                input-classes="form-control-alternative"
                                                v-model="model.city"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <base-input alternative=""
                                                label="Country"
                                                placeholder="Country"
                                                input-classes="form-control-alternative"
                                                v-model="model.country"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <base-input alternative=""
                                                label="Postal code"
                                                placeholder="Postal code"
                                                input-classes="form-control-alternative"
                                                v-model="model.zipCode"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Description -->
                        <!-- <h6 class="heading-small text-muted mb-4">About me</h6>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <base-input alternative=""
                                            label="About Me">
                                    <textarea rows="4" class="form-control form-control-alternative" placeholder="A few words about you ...">A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</textarea>
                                </base-input>
                            </div>
                        </div> -->
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
    name: 'bookabook',
    data() {
    return {
        menuSelected: 'account-details',
        orderLoaded: false,
        model: {
          username: '',
          email: this.$store.state.profileEmail,
          firstName: this.$store.state.profileFirstName,
          lastName: this.$store.state.profileLastName,
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        },
        orders: [],
    }
    },
    async created() {
        firebase.auth().onAuthStateChanged(async (user) => {
            await this.$store.dispatch("getCurrentUser", user);
            this.loadProfileData();
            this.loadOrders();
        })
    },
    computed: {
        products() {
            return this.$store.state.products;
        },
        isAdmin(){
            return this.$store.state.profileAdmin;
        },
    },
    methods: {
    loadProfileData(){
        let data = this.$store.state.profileData;
        if(this.$store.state.profileData){
            this.model.email = data.email;
            this.model.firstName = data.firstName;
            this.model.lastName = data.lastName;
        }
    },
    async loadOrders(){
        this.orderLoaded = false;
        this.orders = [];
        const dataBase = await db.collection('orders').orderBy("date", "desc").where("email",'==',this.$store.state.profileEmail);
        const dbResults = await dataBase.get();
        if(dbResults.empty)
            this.orderLoaded = true;
        dbResults.forEach((doc) => {
            if (!this.orders.some((order) => order.orderID === doc.id)) {
                const data = doc.data();
                data.id = doc.id;
                if(!data.void)
                this.orders.push(data);
            }
            this.orderLoaded = true;
            });
        console.log(dbResults);
        
    },
    selectMenu(selection){
        this.menuSelected = selection;
    },
    scrollToProducts() {
      this.$refs["products"].scrollIntoView({ behavior: "smooth" })
    },
    scrollToTop() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
    logout() {
        if (confirm("Are you sure you want to logout?") == true) {
            firebase.auth().signOut();
            window.location.reload();
        }
      },

  },
}

</script>

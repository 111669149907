<template>
    <div>
        <base-header type="gradient-success" class="pb-3 pt-3 pt-md-8">
        </base-header>

        <div class="container-fluid mt--5 lg-8">
             <!--Tables-->
             <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                        <div class="card-header border-0"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                    Orders
                                </h3>
                            </div>
                            </div>
                        </div>
                    
                        <div class="table-responsive">

                            <content-placeholders v-show="modelArray.length == 0" class="m-5">
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                            </content-placeholders>

                            <base-table class="table align-items-center table-flush"
                                        :class="type === 'dark' ? 'table-dark': ''"
                                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                        tbody-classes="list"
                                        :data="modelArray" v-show="modelArray.length > 0">
                            <template slot="columns">
                                <th>OrderID</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>Pay with</th>
                                <th>Items</th>
                                <th>Subtotal</th>
                                <th>Discount</th>
                                <th>Coupon</th>
                                <th>Total</th>
                                <th></th>
                            </template>
                    
                            <template slot-scope="{row}">
                                <th scope="row">
                                <div class="media align-items-center clickable" @click="view(row.id)">
                                    <a href="#" class="avatar mr-3" v-show="row.img">
                                    <img alt="Image placeholder" :src="row.img">
                                    </a>
                                    <div class="media-body">
                                    <span class="name mb-0 text-sm">{{row.orderID}}</span>
                                    </div>
                                </div>
                                </th>
                                <td>
                                  {{ row.name }}
                                </td>
                                <td>
                                  {{ phoneNumberMasked(row.phoneNumber) }}
                                </td>
                                <td>
                                  <div style="padding: 2px;border-radius: 5px;text-align:center;margin-bottom:1px" :class="{'text-white': true,
                                      'bg-warning': row.orderStatus == 'Unfulfilled' || row.orderStatus == null,
                                      'bg-success': row.orderStatus == 'Fulfilled' || row.orderStatus,}
                                      ">{{(row.orderStatus || "Unfulfilled")}}</div>
                                  <div style="padding: 2px;border-radius: 5px;text-align:center;" :class="{'text-white': true,
                                      'bg-warning': row.paymentStatus == 'Unpaid' || row.paymentStatus == null,
                                      'bg-success': row.paymentStatus == 'Paid' || row.paymentStatus,}
                                      ">{{(row.paymentStatus || "Unpaid")}}</div>
                                </td>
                                <td>
                                  {{ (row.selectedPaymentMethod) ? row.selectedPaymentMethod : 'Bank Transfer' }}
                                </td>
                                <td class="item">
                                  <div v-for="product, index in row.cart" :key="index">
                                    <b>{{ product.productName }}</b>
                                    <span style="color:gray"> for </span>
                                    <b>{{ product.childname }}</b>
                                  </div>
                                </td>
                                
                                <td class="price">
                                  {{ row.totalBeforeDiscount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        }) }}
                                </td>
                                <td class="price">
                                  {{ row.discount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })  }}
                                </td>
                                <td class="price">
                                  {{ row.voucherCode }}
                                </td>
                                <td class="price">
                                  {{ row.total.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        })  }}
                                </td>
                                <td class="text-right">
                                <a slot="title" @click="view(row.id)" class="btn btn-sm btn-icon-only text-light">
                                    <i class="ni ni-bold-right"></i>
                                </a>
                                </td>
                    
                            </template>
                    
                            </base-table>
                        </div>
                
                        <!-- <div class="card-footer d-flex justify-content-end"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <base-pagination total=10></base-pagination>
                        </div> -->
                
                    </div>
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

  export default {
    name: 'tables',
    props: {
        type: {
          type: String
        },
        title: String
      },
    data() {
      return {
        modelName: 'orders',
        modelLoaded: false,
        modelArray: [],
      }
    },
    computed: {
      orderStatusStyle(orderStatus) {
        return {
          'text-white': true,
          'bg-warning': orderStatus == 'Unfulfilled' || orderStatus == null,
          'bg-success': orderStatus == 'Fulfilled',
        }
      },
      paymentStatusStyle(paymentStatus) {
        return {
          'text-white': true,
          'bg-warning': paymentStatus == 'Unpaid' || paymentStatus == null,
          'bg-success': paymentStatus == 'Paid',
        }
      },
    },
    mounted(){
      this.loadModel();
    },
    methods: {
        view(id) {
            this.$router.push({ name: "View Order", params: { id: id } });
        },
        phoneNumberMasked(phoneNumber){
          let maskingX = '';
          for(var i = phoneNumber.length;i>8;i--){
            maskingX += 'x';
          }
          return phoneNumber.substring(0,4)+'-'+maskingX+'-'+phoneNumber.substring(phoneNumber.length-4,phoneNumber.length);
        },
        async loadModel(){
          const dataBase = await db.collection(this.modelName).orderBy("date", "desc");
          const dbResults = await dataBase.get();
          dbResults.forEach((doc) => {
            if (!this.modelArray.some((order) => order.orderID === doc.id)) {
              const data = doc.data();
              data.id = doc.id;
              if(!data.void)
                this.modelArray.push(data);
            }
          });
          this.modelLoaded = true;
        }
    },

  };
</script>
<style>
    .avatar img{ border-radius: 0 }
    .clickable { cursor: pointer; }
    .clickable:hover { color:darkblue}
</style>

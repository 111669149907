<template>
    <div>

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow p-3">
                        <h1>Settings</h1>
                        <h6 class="heading-small text-muted mb-4">AUTH API</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Token"
                                                        placeholder="johndoe@example.com"
                                                        input-classes="form-control-alternative"
                                                        v-model="authMessage"
                                            />
                                            <div class="pb-3">
                                                <button class="btn" @click="checkAuth()">Check Auth!</button>
                                                <button class="btn" @click="getAuth()">Get Auth!</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

  export default {
    data() {
        return {
            apiURL: 'https://us-central1-book-abook.cloudfunctions.net/shipdeoGetToken',
            authMessage: '',
            shipdeo : {}
        }
    },
    created () {
        // window.addEventListener('scroll', this.handleScroll);
        // this.user = firebase.auth().currentUser;
        this.checkAuth();
    },
    methods: {
        async checkAuth(){
            this.authMessage = await this.getToken();
            this.shipdeo.SHIPDEO_CLIENT_ID = 'i7wGkRV2dNYBMyFl';
            this.shipdeo.SHIPDEO_CLIENT_SECRET = 'aBnmIzt3ZJIWNLYw';
            console.log('this.shipdeo', this.shipdeo);
        },
        async getToken(){
            const doc = await db.collection("shipdeo").doc("token").get();
            if (!doc.exists) {
                console.log('No such document!');
                return ('No auth token not found, get the auth!');
            } else {
                // console.log('Document data:', doc.data());
                return doc.data().token
            }
        },
        getAuth(){
            this.$http.post(this.apiURL, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        client_id: this.shipdeo.SHIPDEO_CLIENT_ID,
                        client_secret: this.shipdeo.SHIPDEO_CLIENT_SECRET,
                        grant_type: 'client_credentials'
                    }
                })
                .then((response) => {
                console.log(response.data)
                this.authMessage = response.data;
                this.checkAuth();
            })
            // this.$http.post(this.apiURL, {
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/x-www-form-urlencoded'
            //         },
            //         data: {
            //             client_id: this.shipdeo.SHIPDEO_CLIENT_ID,
            //             client_secret: this.shipdeo.SHIPDEO_CLIENT_SECRET,
            //             grant_type: 'client_credentials'
            //         }
            //     })
            //     .then((response) => {
            //     console.log(response.data)
            //     this.authMessage = response.data;
            // })
        }
    }
  }
</script>
<style>
</style>
